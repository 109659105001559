*,
body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/font/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins light";
  src: url("assets/font/Poppins/Poppins-Light.ttf");
}
body {
  font-family: "Poppins";
  background-color: #060606;
  height: 100%;
  min-width: 280px;
  -webkit-touch-callout: none;
}

html {
  height: 100%;
  scroll-behavior: smooth !important;
}

img {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
  -webkit-touch-callout: none !important;
}

.active {
  color: #fff;
  position: relative;
  height: 100%;
  z-index: 2;
}
.active::after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff4900;
  z-index: -1;
  opacity: 0;
  padding: 3.5rem;
  -webkit-transform: translate(-27.5%, -150%);
          transform: translate(-27.5%, -150%);
}
.active.yes::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff4900;
  z-index: -1;
  opacity: 1;
  padding: 3.5rem;
  -webkit-transform: translate(-27.5%, -45%);
          transform: translate(-27.5%, -45%);
}

.helloText {
  position: relative !important;
  z-index: 2;
  background-color: #ff4900;
  padding: 2% 3%;
  font-family: "Poppins light";
}
.helloText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 200%;
  min-width: 2rem;
  min-height: 2rem;
  background-color: #ff4900;
  z-index: -1;
  -webkit-transform: translate(-50%, -25%);
          transform: translate(-50%, -25%);
  -webkit-clip-path: polygon(48% 44%, 19% 0, 81% 0);
          clip-path: polygon(48% 44%, 19% 0, 81% 0);
}

.poppins-light {
  font-family: "Poppins light";
  opacity: 0.8;
}

.filter-img {
  -webkit-filter: drop-shadow(1.3vw 0vw 0.5vw rgba(0, 0, 0, 0.585)) brightness(0.5) opacity(0.8);
          filter: drop-shadow(1.3vw 0vw 0.5vw rgba(0, 0, 0, 0.585)) brightness(0.5) opacity(0.8);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  width: 100%;
}

.six {
  width: 49.2%;
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

/* ALL */
.row .three {
  padding: 80px 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
}

.hamburger .line {
  width: 50px;
  height: 5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-6.is-active {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: fixed;
  right: 2%;
}

.is-active .line {
  background-color: #ff4900;
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.544);
          box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.544);
}

#hamburger-6.is-active .line:nth-child(2) {
  width: 0px;
}

#hamburger-6.is-active .line:nth-child(1),
#hamburger-6.is-active .line:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

#hamburger-6.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px);
  transform: translateY(13px);
}

#hamburger-6.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-13px) rotate(90deg);
}

@supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
  .my-bc-blur {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
}
@supports not ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
  .my-bc-blur {
    background-color: rgba(0, 0, 0, 0.837);
  }
}
.My-Menu {
  -webkit-clip-path: polygon(45% 0, 100% 0%, 57% 100%, 0% 100%);
          clip-path: polygon(45% 0, 100% 0%, 57% 100%, 0% 100%);
  background-color: rgba(224, 64, 0, 0.8745098039);
}

.MenuLinks {
  display: grid;
  place-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  min-height: 100vh;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
  -ms-touch-action: none;
      touch-action: none;
}
.MenuLinks * {
  -webkit-transform: rotate(-25deg);
          transform: rotate(-25deg);
}

@media screen and (max-width: 460px) {
  .MenuLinks {
    -webkit-transform: rotate(13deg);
            transform: rotate(13deg);
  }
  .MenuLinks * {
    -webkit-transform: rotate(-13deg);
            transform: rotate(-13deg);
  }
}
@media screen and (max-width: 767px) {
  .active {
    color: #fff;
    position: relative;
    height: 100%;
    z-index: 2;
  }
  .active::after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    top: 0;
    left: 0;
    width: 100%;
    height: 1%;
    background-color: #ff4900;
    z-index: -1;
    opacity: 0;
    padding: 0;
  }
  .active.yes::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2%;
    background-color: #ffffff;
    z-index: -1;
    opacity: 1;
    padding: 0;
    -webkit-transform: translate(0%, -15%);
            transform: translate(0%, -15%);
  }
}
.arrowScrollDownAnimation {
  -webkit-animation: arrowScrollDownAnimation 3s ease-in-out infinite;
          animation: arrowScrollDownAnimation 3s ease-in-out infinite;
}

@-webkit-keyframes arrowScrollDownAnimation {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    opacity: 0;
  }
  80% {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    opacity: 0;
  }
}

@keyframes arrowScrollDownAnimation {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    opacity: 0;
  }
  80% {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    opacity: 0;
  }
}
.line-H {
  line-height: 90%;
}

.ab-img {
  min-height: 15rem;
}

.exp-shadow {
  -webkit-box-shadow: 0 0 1vw rgba(0, 0, 0, 0.544);
          box-shadow: 0 0 1vw rgba(0, 0, 0, 0.544);
}

.path-0 {
  -webkit-animation: pathAnim-0 7s;
          animation: pathAnim-0 7s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes pathAnim-0 {
  0% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 38.52752863579052,113.53503221513658 77.05505727158103,127.07006443027316 106,133 C 134.94494272841897,138.92993556972684 154.3072995494664,137.25477449404391 189,125 C 223.6927004505336,112.74522550595609 273.7157445305535,89.91083759355118 311,94 C 348.2842554694465,98.08916240644882 372.8297223283198,129.1018751317514 409,130 C 445.1702776716802,130.8981248682486 492.9653661561673,101.68166187944318 525,98 C 557.0346338438327,94.31833812055682 573.3088130470111,116.17147735047584 600,124 C 626.6911869529889,131.82852264952416 663.7993816557885,125.63242871865337 705,113 C 746.2006183442115,100.36757128134663 791.4936603298354,81.29880777491063 828,89 C 864.5063396701646,96.70119222508937 892.2259770248702,131.1723401817042 926,130 C 959.7740229751298,128.8276598182958 999.6024315706841,92.01183149827264 1039,81 C 1078.397568429316,69.98816850172736 1117.364296692394,84.78033382520528 1144,93 C 1170.635703307606,101.21966617479472 1184.9403816597398,102.86683320090627 1220,111 C 1255.0596183402602,119.13316679909373 1310.8741766686458,133.75233337116964 1351,133 C 1391.1258233313542,132.24766662883036 1415.5629116656771,116.12383331441518 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  25% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 30.22484575120346,84.03355421467573 60.44969150240692,68.06710842935146 93,63 C 125.55030849759308,57.93289157064853 160.4260797415758,63.76512049726986 195,68 C 229.5739202584242,72.23487950273014 263.84598953128994,74.87240958156909 303,72 C 342.15401046871006,69.12759041843091 386.1899621332644,60.74524117645376 427,74 C 467.8100378667356,87.25475882354624 505.3941619356525,122.14662571261589 533,121 C 560.6058380643475,119.85337428738411 578.2333901241252,82.66825597308271 607,68 C 635.7666098758748,53.33174402691729 675.6722775678462,61.18035039505325 714,75 C 752.3277224321538,88.81964960494675 789.0774996044902,108.6103424467043 828,113 C 866.9225003955098,117.3896575532957 908.0177240141928,106.37827981812957 938,108 C 967.9822759858072,109.62172018187043 986.8516043387383,123.87653828077744 1015,113 C 1043.1483956612617,102.12346171922256 1080.5758586308536,66.11556705876069 1117,65 C 1153.4241413691464,63.88443294123932 1188.8449611378471,97.6611934841798 1222,107 C 1255.1550388621529,116.3388065158202 1286.044296817758,101.23965900452005 1322,96 C 1357.955703182242,90.76034099547995 1398.977851591121,95.38017049773998 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  50% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 39.08186806231298,101.09023985536265 78.16373612462596,102.18047971072532 107,106 C 135.83626387537404,109.81952028927468 154.4269235638091,116.36832101246134 190,123 C 225.5730764361909,129.63167898753866 278.12856962013757,136.34623623942937 316,127 C 353.87143037986243,117.65376376057063 377.05879795564056,92.24673402982111 403,93 C 428.94120204435944,93.75326597017889 457.6362385573003,120.6668276412862 499,129 C 540.3637614426997,137.3331723587138 594.3962478151578,127.085955405034 635,111 C 675.6037521848422,94.914044594966 702.778770182068,72.98935073857783 727,65 C 751.221229817932,57.01064926142218 772.48867145657,62.95664164065478 804,74 C 835.51132854343,85.04335835934522 877.2665439916518,101.1840826988031 914,100 C 950.7334560083482,98.8159173011969 982.4451525768227,80.30702756413288 1023,79 C 1063.5548474231773,77.69297243586712 1112.9528457010579,93.58780704466541 1147,101 C 1181.0471542989421,108.41219295533459 1199.743464618946,107.34174425720545 1225,101 C 1250.256535381054,94.65825574279455 1282.0732958231583,83.04521592651274 1319,82 C 1355.9267041768417,80.95478407348726 1397.9633520884208,90.47739203674362 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  75% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 26.71628250555179,88.09059414487525 53.43256501110358,76.18118828975051 91,73 C 128.56743498889642,69.81881171024949 176.9860224611375,75.36584098587323 218,71 C 259.0139775388625,66.63415901412677 292.6233451443464,52.35544776675651 327,67 C 361.3766548556536,81.64455223324349 396.52059696147694,125.21236794710074 430,127 C 463.47940303852306,128.78763205289926 495.294267009746,88.79508044484047 528,71 C 560.705732990254,53.20491955515953 594.3023349995387,57.607310273537394 622,63 C 649.6976650004613,68.3926897264626 671.496392992099,74.77567846100997 711,74 C 750.503607007901,73.22432153899003 807.7120930320656,65.28997588242275 839,75 C 870.2879069679344,84.71002411757725 875.6552348796389,112.06441800929903 908,124 C 940.3447651203611,135.93558199070097 999.6669674493785,132.4523520803812 1038,131 C 1076.3330325506215,129.5476479196188 1093.6768953228468,130.12617366917618 1126,117 C 1158.3231046771532,103.87382633082382 1205.6254512592336,77.0429532429141 1238,78 C 1270.3745487407664,78.9570467570859 1287.821299640219,107.70201335916741 1319,116 C 1350.178700359781,124.29798664083259 1395.0893501798905,112.1489933204163 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  100% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 38.52752863579052,113.53503221513658 77.05505727158103,127.07006443027316 106,133 C 134.94494272841897,138.92993556972684 154.3072995494664,137.25477449404391 189,125 C 223.6927004505336,112.74522550595609 273.7157445305535,89.91083759355118 311,94 C 348.2842554694465,98.08916240644882 372.8297223283198,129.1018751317514 409,130 C 445.1702776716802,130.8981248682486 492.9653661561673,101.68166187944318 525,98 C 557.0346338438327,94.31833812055682 573.3088130470111,116.17147735047584 600,124 C 626.6911869529889,131.82852264952416 663.7993816557885,125.63242871865337 705,113 C 746.2006183442115,100.36757128134663 791.4936603298354,81.29880777491063 828,89 C 864.5063396701646,96.70119222508937 892.2259770248702,131.1723401817042 926,130 C 959.7740229751298,128.8276598182958 999.6024315706841,92.01183149827264 1039,81 C 1078.397568429316,69.98816850172736 1117.364296692394,84.78033382520528 1144,93 C 1170.635703307606,101.21966617479472 1184.9403816597398,102.86683320090627 1220,111 C 1255.0596183402602,119.13316679909373 1310.8741766686458,133.75233337116964 1351,133 C 1391.1258233313542,132.24766662883036 1415.5629116656771,116.12383331441518 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
}

@keyframes pathAnim-0 {
  0% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 38.52752863579052,113.53503221513658 77.05505727158103,127.07006443027316 106,133 C 134.94494272841897,138.92993556972684 154.3072995494664,137.25477449404391 189,125 C 223.6927004505336,112.74522550595609 273.7157445305535,89.91083759355118 311,94 C 348.2842554694465,98.08916240644882 372.8297223283198,129.1018751317514 409,130 C 445.1702776716802,130.8981248682486 492.9653661561673,101.68166187944318 525,98 C 557.0346338438327,94.31833812055682 573.3088130470111,116.17147735047584 600,124 C 626.6911869529889,131.82852264952416 663.7993816557885,125.63242871865337 705,113 C 746.2006183442115,100.36757128134663 791.4936603298354,81.29880777491063 828,89 C 864.5063396701646,96.70119222508937 892.2259770248702,131.1723401817042 926,130 C 959.7740229751298,128.8276598182958 999.6024315706841,92.01183149827264 1039,81 C 1078.397568429316,69.98816850172736 1117.364296692394,84.78033382520528 1144,93 C 1170.635703307606,101.21966617479472 1184.9403816597398,102.86683320090627 1220,111 C 1255.0596183402602,119.13316679909373 1310.8741766686458,133.75233337116964 1351,133 C 1391.1258233313542,132.24766662883036 1415.5629116656771,116.12383331441518 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  25% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 30.22484575120346,84.03355421467573 60.44969150240692,68.06710842935146 93,63 C 125.55030849759308,57.93289157064853 160.4260797415758,63.76512049726986 195,68 C 229.5739202584242,72.23487950273014 263.84598953128994,74.87240958156909 303,72 C 342.15401046871006,69.12759041843091 386.1899621332644,60.74524117645376 427,74 C 467.8100378667356,87.25475882354624 505.3941619356525,122.14662571261589 533,121 C 560.6058380643475,119.85337428738411 578.2333901241252,82.66825597308271 607,68 C 635.7666098758748,53.33174402691729 675.6722775678462,61.18035039505325 714,75 C 752.3277224321538,88.81964960494675 789.0774996044902,108.6103424467043 828,113 C 866.9225003955098,117.3896575532957 908.0177240141928,106.37827981812957 938,108 C 967.9822759858072,109.62172018187043 986.8516043387383,123.87653828077744 1015,113 C 1043.1483956612617,102.12346171922256 1080.5758586308536,66.11556705876069 1117,65 C 1153.4241413691464,63.88443294123932 1188.8449611378471,97.6611934841798 1222,107 C 1255.1550388621529,116.3388065158202 1286.044296817758,101.23965900452005 1322,96 C 1357.955703182242,90.76034099547995 1398.977851591121,95.38017049773998 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  50% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 39.08186806231298,101.09023985536265 78.16373612462596,102.18047971072532 107,106 C 135.83626387537404,109.81952028927468 154.4269235638091,116.36832101246134 190,123 C 225.5730764361909,129.63167898753866 278.12856962013757,136.34623623942937 316,127 C 353.87143037986243,117.65376376057063 377.05879795564056,92.24673402982111 403,93 C 428.94120204435944,93.75326597017889 457.6362385573003,120.6668276412862 499,129 C 540.3637614426997,137.3331723587138 594.3962478151578,127.085955405034 635,111 C 675.6037521848422,94.914044594966 702.778770182068,72.98935073857783 727,65 C 751.221229817932,57.01064926142218 772.48867145657,62.95664164065478 804,74 C 835.51132854343,85.04335835934522 877.2665439916518,101.1840826988031 914,100 C 950.7334560083482,98.8159173011969 982.4451525768227,80.30702756413288 1023,79 C 1063.5548474231773,77.69297243586712 1112.9528457010579,93.58780704466541 1147,101 C 1181.0471542989421,108.41219295533459 1199.743464618946,107.34174425720545 1225,101 C 1250.256535381054,94.65825574279455 1282.0732958231583,83.04521592651274 1319,82 C 1355.9267041768417,80.95478407348726 1397.9633520884208,90.47739203674362 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  75% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 26.71628250555179,88.09059414487525 53.43256501110358,76.18118828975051 91,73 C 128.56743498889642,69.81881171024949 176.9860224611375,75.36584098587323 218,71 C 259.0139775388625,66.63415901412677 292.6233451443464,52.35544776675651 327,67 C 361.3766548556536,81.64455223324349 396.52059696147694,125.21236794710074 430,127 C 463.47940303852306,128.78763205289926 495.294267009746,88.79508044484047 528,71 C 560.705732990254,53.20491955515953 594.3023349995387,57.607310273537394 622,63 C 649.6976650004613,68.3926897264626 671.496392992099,74.77567846100997 711,74 C 750.503607007901,73.22432153899003 807.7120930320656,65.28997588242275 839,75 C 870.2879069679344,84.71002411757725 875.6552348796389,112.06441800929903 908,124 C 940.3447651203611,135.93558199070097 999.6669674493785,132.4523520803812 1038,131 C 1076.3330325506215,129.5476479196188 1093.6768953228468,130.12617366917618 1126,117 C 1158.3231046771532,103.87382633082382 1205.6254512592336,77.0429532429141 1238,78 C 1270.3745487407664,78.9570467570859 1287.821299640219,107.70201335916741 1319,116 C 1350.178700359781,124.29798664083259 1395.0893501798905,112.1489933204163 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
  100% {
    d: path("M 0,400 C 0,400 0,100 0,100 C 38.52752863579052,113.53503221513658 77.05505727158103,127.07006443027316 106,133 C 134.94494272841897,138.92993556972684 154.3072995494664,137.25477449404391 189,125 C 223.6927004505336,112.74522550595609 273.7157445305535,89.91083759355118 311,94 C 348.2842554694465,98.08916240644882 372.8297223283198,129.1018751317514 409,130 C 445.1702776716802,130.8981248682486 492.9653661561673,101.68166187944318 525,98 C 557.0346338438327,94.31833812055682 573.3088130470111,116.17147735047584 600,124 C 626.6911869529889,131.82852264952416 663.7993816557885,125.63242871865337 705,113 C 746.2006183442115,100.36757128134663 791.4936603298354,81.29880777491063 828,89 C 864.5063396701646,96.70119222508937 892.2259770248702,131.1723401817042 926,130 C 959.7740229751298,128.8276598182958 999.6024315706841,92.01183149827264 1039,81 C 1078.397568429316,69.98816850172736 1117.364296692394,84.78033382520528 1144,93 C 1170.635703307606,101.21966617479472 1184.9403816597398,102.86683320090627 1220,111 C 1255.0596183402602,119.13316679909373 1310.8741766686458,133.75233337116964 1351,133 C 1391.1258233313542,132.24766662883036 1415.5629116656771,116.12383331441518 1440,100 C 1440,100 1440,400 1440,400 Z");
  }
}
.path-1 {
  -webkit-animation: pathAnim-1 4s;
          animation: pathAnim-1 4s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes pathAnim-1 {
  0% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 40.45759706408457,202.76312659677262 80.91519412816913,205.52625319354522 116,210 C 151.08480587183087,214.47374680645478 180.79682055140802,220.65811382259173 209,221 C 237.20317944859198,221.34188617740827 263.89752366619877,215.84129151608795 295,205 C 326.10247633380123,194.15870848391205 361.61308478379686,177.9767201130565 402,174 C 442.38691521620314,170.0232798869435 487.6501371986137,178.25182803168602 521,177 C 554.3498628013863,175.74817196831398 575.7863664217481,165.01596776019943 608,177 C 640.2136335782519,188.98403223980057 683.2043971143937,223.6843009275163 724,225 C 764.7956028856063,226.3156990724837 803.3960451206772,194.24682852973532 839,182 C 874.6039548793228,169.75317147026468 907.2114224028976,177.3283849535424 942,186 C 976.7885775971024,194.6716150464576 1013.7582652677318,204.43963165609497 1043,213 C 1072.2417347322682,221.56036834390503 1093.755516526175,228.91308842207764 1121,236 C 1148.244483473825,243.08691157792236 1181.2196686275684,249.9080146555945 1219,234 C 1256.7803313724316,218.0919853444055 1299.365808963552,179.4548529555444 1337,170 C 1374.634191036448,160.5451470444556 1407.317095518224,180.2725735222278 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  25% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 36.5751243658388,182.67396930026868 73.1502487316776,165.34793860053736 108,164 C 142.8497512683224,162.65206139946264 175.97412943912838,177.2822148981193 207,181 C 238.02587056087162,184.7177851018807 266.95323351180895,177.52320180698553 299,188 C 331.04676648819105,198.47679819301447 366.21293651363584,226.6249778739386 405,226 C 443.78706348636416,225.3750221260614 486.19502043364764,195.97688669726 522,187 C 557.8049795663524,178.02311330274 587.0069817517738,189.4674753370213 619,196 C 650.9930182482262,202.5325246629787 685.777052559258,204.15321195465478 725,205 C 764.222947440742,205.84678804534522 807.8848080111944,205.91967684435966 838,202 C 868.1151919888056,198.08032315564034 884.6837153959642,190.1680806679066 914,195 C 943.3162846040358,199.8319193320934 985.3803304049488,217.408000484014 1018,216 C 1050.6196695950512,214.591999515986 1073.7949629842406,194.19991739603748 1114,185 C 1154.2050370157594,175.80008260396252 1211.4398176580892,177.79232993183606 1253,178 C 1294.5601823419108,178.20767006816394 1320.4457663834032,176.6307628766183 1349,180 C 1377.5542336165968,183.3692371233817 1408.7771168082984,191.68461856169085 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  50% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 35.67619355708169,213.13525376872064 71.35238711416338,226.27050753744126 103,225 C 134.64761288583662,223.72949246255874 162.26664510042812,208.05322361895566 198,199 C 233.73335489957188,189.94677638104434 277.58103248412414,187.5165979867362 311,179 C 344.41896751587586,170.4834020132638 367.4092249630755,155.8803844340995 398,171 C 428.5907750369245,186.1196155659005 466.7820676635738,230.96186427686578 508,236 C 549.2179323364262,241.03813572313422 593.4625043826294,206.27215845843725 631,203 C 668.5374956173706,199.72784154156275 699.3679148059088,227.94950188938512 728,225 C 756.6320851940912,222.05049811061488 783.0658363937359,187.92983398402237 821,183 C 858.9341636062641,178.07016601597763 908.3687396191472,202.33116217452545 938,214 C 967.6312603808528,225.66883782547455 977.4592051296761,224.74551731787577 1010,217 C 1042.540794870324,209.25448268212423 1097.7944398621487,194.68676855397146 1140,197 C 1182.2055601378513,199.31323144602854 1211.3630354217291,218.50740846623845 1239,215 C 1266.6369645782709,211.49259153376155 1292.7534184509345,185.28359758107473 1326,179 C 1359.2465815490655,172.71640241892527 1399.6232907745327,186.35820120946264 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  75% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 39.27516460495151,205.2871575774828 78.55032920990303,210.57431515496555 115,211 C 151.44967079009697,211.42568484503445 185.0738477653394,206.98989695762057 212,199 C 238.9261522346606,191.01010304237943 259.15427972873954,179.46609701455213 292,182 C 324.84572027126046,184.53390298544787 370.3090333197024,201.14571498417075 412,209 C 453.6909666802976,216.85428501582925 491.6095869924512,215.95104304876483 524,215 C 556.3904130075488,214.04895695123517 583.2526187104926,213.05011282076995 618,211 C 652.7473812895074,208.94988717923005 695.3799381655788,205.84850566815527 727,208 C 758.6200618344212,210.15149433184473 779.2276286271923,217.55586450660897 812,220 C 844.7723713728077,222.44413549339103 889.7095473256522,219.928036305409 931,220 C 972.2904526743478,220.071963694591 1009.9341820701982,222.73199027175502 1044,219 C 1078.0658179298018,215.26800972824498 1108.553724393555,205.14400260757083 1144,209 C 1179.446275606445,212.85599739242917 1219.8509203555814,230.6919992979617 1249,222 C 1278.1490796444186,213.3080007020383 1296.0425941841197,178.08800020058237 1326,170 C 1355.9574058158803,161.91199979941763 1397.9787029079403,180.95599989970881 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  100% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 40.45759706408457,202.76312659677262 80.91519412816913,205.52625319354522 116,210 C 151.08480587183087,214.47374680645478 180.79682055140802,220.65811382259173 209,221 C 237.20317944859198,221.34188617740827 263.89752366619877,215.84129151608795 295,205 C 326.10247633380123,194.15870848391205 361.61308478379686,177.9767201130565 402,174 C 442.38691521620314,170.0232798869435 487.6501371986137,178.25182803168602 521,177 C 554.3498628013863,175.74817196831398 575.7863664217481,165.01596776019943 608,177 C 640.2136335782519,188.98403223980057 683.2043971143937,223.6843009275163 724,225 C 764.7956028856063,226.3156990724837 803.3960451206772,194.24682852973532 839,182 C 874.6039548793228,169.75317147026468 907.2114224028976,177.3283849535424 942,186 C 976.7885775971024,194.6716150464576 1013.7582652677318,204.43963165609497 1043,213 C 1072.2417347322682,221.56036834390503 1093.755516526175,228.91308842207764 1121,236 C 1148.244483473825,243.08691157792236 1181.2196686275684,249.9080146555945 1219,234 C 1256.7803313724316,218.0919853444055 1299.365808963552,179.4548529555444 1337,170 C 1374.634191036448,160.5451470444556 1407.317095518224,180.2725735222278 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
}

@keyframes pathAnim-1 {
  0% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 40.45759706408457,202.76312659677262 80.91519412816913,205.52625319354522 116,210 C 151.08480587183087,214.47374680645478 180.79682055140802,220.65811382259173 209,221 C 237.20317944859198,221.34188617740827 263.89752366619877,215.84129151608795 295,205 C 326.10247633380123,194.15870848391205 361.61308478379686,177.9767201130565 402,174 C 442.38691521620314,170.0232798869435 487.6501371986137,178.25182803168602 521,177 C 554.3498628013863,175.74817196831398 575.7863664217481,165.01596776019943 608,177 C 640.2136335782519,188.98403223980057 683.2043971143937,223.6843009275163 724,225 C 764.7956028856063,226.3156990724837 803.3960451206772,194.24682852973532 839,182 C 874.6039548793228,169.75317147026468 907.2114224028976,177.3283849535424 942,186 C 976.7885775971024,194.6716150464576 1013.7582652677318,204.43963165609497 1043,213 C 1072.2417347322682,221.56036834390503 1093.755516526175,228.91308842207764 1121,236 C 1148.244483473825,243.08691157792236 1181.2196686275684,249.9080146555945 1219,234 C 1256.7803313724316,218.0919853444055 1299.365808963552,179.4548529555444 1337,170 C 1374.634191036448,160.5451470444556 1407.317095518224,180.2725735222278 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  25% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 36.5751243658388,182.67396930026868 73.1502487316776,165.34793860053736 108,164 C 142.8497512683224,162.65206139946264 175.97412943912838,177.2822148981193 207,181 C 238.02587056087162,184.7177851018807 266.95323351180895,177.52320180698553 299,188 C 331.04676648819105,198.47679819301447 366.21293651363584,226.6249778739386 405,226 C 443.78706348636416,225.3750221260614 486.19502043364764,195.97688669726 522,187 C 557.8049795663524,178.02311330274 587.0069817517738,189.4674753370213 619,196 C 650.9930182482262,202.5325246629787 685.777052559258,204.15321195465478 725,205 C 764.222947440742,205.84678804534522 807.8848080111944,205.91967684435966 838,202 C 868.1151919888056,198.08032315564034 884.6837153959642,190.1680806679066 914,195 C 943.3162846040358,199.8319193320934 985.3803304049488,217.408000484014 1018,216 C 1050.6196695950512,214.591999515986 1073.7949629842406,194.19991739603748 1114,185 C 1154.2050370157594,175.80008260396252 1211.4398176580892,177.79232993183606 1253,178 C 1294.5601823419108,178.20767006816394 1320.4457663834032,176.6307628766183 1349,180 C 1377.5542336165968,183.3692371233817 1408.7771168082984,191.68461856169085 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  50% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 35.67619355708169,213.13525376872064 71.35238711416338,226.27050753744126 103,225 C 134.64761288583662,223.72949246255874 162.26664510042812,208.05322361895566 198,199 C 233.73335489957188,189.94677638104434 277.58103248412414,187.5165979867362 311,179 C 344.41896751587586,170.4834020132638 367.4092249630755,155.8803844340995 398,171 C 428.5907750369245,186.1196155659005 466.7820676635738,230.96186427686578 508,236 C 549.2179323364262,241.03813572313422 593.4625043826294,206.27215845843725 631,203 C 668.5374956173706,199.72784154156275 699.3679148059088,227.94950188938512 728,225 C 756.6320851940912,222.05049811061488 783.0658363937359,187.92983398402237 821,183 C 858.9341636062641,178.07016601597763 908.3687396191472,202.33116217452545 938,214 C 967.6312603808528,225.66883782547455 977.4592051296761,224.74551731787577 1010,217 C 1042.540794870324,209.25448268212423 1097.7944398621487,194.68676855397146 1140,197 C 1182.2055601378513,199.31323144602854 1211.3630354217291,218.50740846623845 1239,215 C 1266.6369645782709,211.49259153376155 1292.7534184509345,185.28359758107473 1326,179 C 1359.2465815490655,172.71640241892527 1399.6232907745327,186.35820120946264 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  75% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 39.27516460495151,205.2871575774828 78.55032920990303,210.57431515496555 115,211 C 151.44967079009697,211.42568484503445 185.0738477653394,206.98989695762057 212,199 C 238.9261522346606,191.01010304237943 259.15427972873954,179.46609701455213 292,182 C 324.84572027126046,184.53390298544787 370.3090333197024,201.14571498417075 412,209 C 453.6909666802976,216.85428501582925 491.6095869924512,215.95104304876483 524,215 C 556.3904130075488,214.04895695123517 583.2526187104926,213.05011282076995 618,211 C 652.7473812895074,208.94988717923005 695.3799381655788,205.84850566815527 727,208 C 758.6200618344212,210.15149433184473 779.2276286271923,217.55586450660897 812,220 C 844.7723713728077,222.44413549339103 889.7095473256522,219.928036305409 931,220 C 972.2904526743478,220.071963694591 1009.9341820701982,222.73199027175502 1044,219 C 1078.0658179298018,215.26800972824498 1108.553724393555,205.14400260757083 1144,209 C 1179.446275606445,212.85599739242917 1219.8509203555814,230.6919992979617 1249,222 C 1278.1490796444186,213.3080007020383 1296.0425941841197,178.08800020058237 1326,170 C 1355.9574058158803,161.91199979941763 1397.9787029079403,180.95599989970881 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
  100% {
    d: path("M 0,400 C 0,400 0,200 0,200 C 40.45759706408457,202.76312659677262 80.91519412816913,205.52625319354522 116,210 C 151.08480587183087,214.47374680645478 180.79682055140802,220.65811382259173 209,221 C 237.20317944859198,221.34188617740827 263.89752366619877,215.84129151608795 295,205 C 326.10247633380123,194.15870848391205 361.61308478379686,177.9767201130565 402,174 C 442.38691521620314,170.0232798869435 487.6501371986137,178.25182803168602 521,177 C 554.3498628013863,175.74817196831398 575.7863664217481,165.01596776019943 608,177 C 640.2136335782519,188.98403223980057 683.2043971143937,223.6843009275163 724,225 C 764.7956028856063,226.3156990724837 803.3960451206772,194.24682852973532 839,182 C 874.6039548793228,169.75317147026468 907.2114224028976,177.3283849535424 942,186 C 976.7885775971024,194.6716150464576 1013.7582652677318,204.43963165609497 1043,213 C 1072.2417347322682,221.56036834390503 1093.755516526175,228.91308842207764 1121,236 C 1148.244483473825,243.08691157792236 1181.2196686275684,249.9080146555945 1219,234 C 1256.7803313724316,218.0919853444055 1299.365808963552,179.4548529555444 1337,170 C 1374.634191036448,160.5451470444556 1407.317095518224,180.2725735222278 1440,200 C 1440,200 1440,400 1440,400 Z");
  }
}
.path-2 {
  -webkit-animation: pathAnim-2 4s;
          animation: pathAnim-2 4s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes pathAnim-2 {
  0% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 34.31907061412407,293.2342614037959 68.63814122824814,286.4685228075918 100,287 C 131.36185877175186,287.5314771924082 159.7665057011315,295.3601701734288 196,294 C 232.2334942988685,292.6398298265712 276.29583596722597,282.09079649869295 313,282 C 349.70416403277403,281.90920350130705 379.05015042996445,292.27664383179916 409,289 C 438.94984957003555,285.72335616820084 469.5035623129163,268.80262817411045 506,269 C 542.4964376870837,269.19737182588955 584.93560031837,286.51284347175925 617,295 C 649.06439968163,303.48715652824075 670.7540364136036,303.14599793885264 708,294 C 745.2459635863964,284.85400206114736 798.0482540272159,266.9031647728303 838,272 C 877.9517459727841,277.0968352271697 905.0529474775335,305.2413429698264 934,315 C 962.9470525224665,324.7586570301736 993.73995606265,316.1314633478642 1031,310 C 1068.26004393735,303.8685366521358 1111.987228271867,300.2328036387168 1148,306 C 1184.012771728133,311.7671963612832 1212.3111308498821,326.9373220972685 1244,332 C 1275.6888691501179,337.0626779027315 1310.768248328605,332.017907972209 1344,325 C 1377.231751671395,317.982092027791 1408.6158758356974,308.99104601389547 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  25% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 33.77022914764348,302.1347365741649 67.54045829528695,304.2694731483297 102,295 C 136.45954170471305,285.7305268516703 171.60839596649564,265.056843980846 203,267 C 234.39160403350436,268.943156019154 262.02595783873045,293.50315092828623 296,298 C 329.97404216126955,302.49684907171377 370.2877726785826,286.93055230600913 409,278 C 447.7122273214174,269.06944769399087 484.8229514469389,266.77463984767735 519,263 C 553.1770485530611,259.22536015232265 584.420421533662,253.97088830328164 617,268 C 649.579578466338,282.02911169671836 683.495362418413,315.3418069391962 716,328 C 748.504637581587,340.6581930608038 779.5981287926862,332.6618839399337 815,333 C 850.4018712073138,333.3381160600663 890.1121224108421,342.0106573010688 924,331 C 957.8878775891579,319.9893426989312 985.9533815639456,289.29548685579095 1016,291 C 1046.0466184360544,292.70451314420905 1078.0743513333753,326.8073952757674 1115,330 C 1151.9256486666247,333.1926047242326 1193.7492131025526,305.47493204113954 1236,292 C 1278.2507868974474,278.52506795886046 1320.9287962564135,279.2928765596744 1355,283 C 1389.0712037435865,286.7071234403256 1414.5356018717932,293.3535617201628 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  50% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 32.30827112032203,299.5664506772448 64.61654224064407,299.13290135448966 96,304 C 127.38345775935593,308.86709864551034 157.8421021577458,319.0348452592861 197,329 C 236.1578978422542,338.9651547407139 284.0150491283728,348.72771760836594 322,334 C 359.9849508716272,319.27228239163406 388.09770132876287,280.0542843072502 423,283 C 457.90229867123713,285.9457156927498 499.5941455565759,331.05514516263315 531,328 C 562.4058544434241,324.94485483736685 583.5257164449337,273.72513504221695 612,273 C 640.4742835550663,272.27486495778305 676.3029886636895,322.04431466849877 718,336 C 759.6970113363105,349.95568533150123 807.2623289003086,328.0976062837878 839,313 C 870.7376710996914,297.9023937162122 886.6476957350764,289.56526019635004 916,288 C 945.3523042649236,286.43473980364996 988.1468881593855,291.6413529308122 1030,298 C 1071.8531118406145,304.3586470691878 1112.7647516273812,311.8693280804008 1145,312 C 1177.2352483726188,312.1306719195992 1200.7941053310897,304.88133474758445 1235,307 C 1269.2058946689103,309.11866525241555 1314.0588270482601,320.6053329292616 1350,321 C 1385.9411729517399,321.3946670707384 1412.9705864758698,310.69733353536924 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  75% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 39.17018453441793,290.42580319135817 78.34036906883586,280.85160638271634 111,278 C 143.65963093116414,275.14839361728366 169.8087082590745,279.01937766049275 206,278 C 242.1912917409255,276.98062233950725 288.4247978948662,271.0708829753125 325,272 C 361.5752021051338,272.9291170246875 388.4921001614607,280.6970904382575 421,277 C 453.5078998385393,273.3029095617425 491.6068014592911,258.14075527165755 523,270 C 554.3931985407089,281.85924472834245 579.0806940013742,320.739888475112 612,332 C 644.9193059986258,343.260111524888 686.0704225352113,326.89969082789423 728,323 C 769.9295774647887,319.10030917210577 812.6376158577805,327.66134821331116 839,326 C 865.3623841422195,324.33865178668884 875.3791140336666,312.45491631886114 910,310 C 944.6208859663334,307.54508368113886 1003.8459280075535,314.5189865112444 1045,311 C 1086.1540719924465,307.4810134887556 1109.23717393612,293.4691376361613 1136,284 C 1162.76282606388,274.5308623638387 1193.2053762479677,269.60446294411037 1229,283 C 1264.7946237520323,296.39553705588963 1305.9413210720093,328.11301058739707 1342,334 C 1378.0586789279907,339.88698941260293 1409.0293394639953,319.94349470630146 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  100% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 34.31907061412407,293.2342614037959 68.63814122824814,286.4685228075918 100,287 C 131.36185877175186,287.5314771924082 159.7665057011315,295.3601701734288 196,294 C 232.2334942988685,292.6398298265712 276.29583596722597,282.09079649869295 313,282 C 349.70416403277403,281.90920350130705 379.05015042996445,292.27664383179916 409,289 C 438.94984957003555,285.72335616820084 469.5035623129163,268.80262817411045 506,269 C 542.4964376870837,269.19737182588955 584.93560031837,286.51284347175925 617,295 C 649.06439968163,303.48715652824075 670.7540364136036,303.14599793885264 708,294 C 745.2459635863964,284.85400206114736 798.0482540272159,266.9031647728303 838,272 C 877.9517459727841,277.0968352271697 905.0529474775335,305.2413429698264 934,315 C 962.9470525224665,324.7586570301736 993.73995606265,316.1314633478642 1031,310 C 1068.26004393735,303.8685366521358 1111.987228271867,300.2328036387168 1148,306 C 1184.012771728133,311.7671963612832 1212.3111308498821,326.9373220972685 1244,332 C 1275.6888691501179,337.0626779027315 1310.768248328605,332.017907972209 1344,325 C 1377.231751671395,317.982092027791 1408.6158758356974,308.99104601389547 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
}

@keyframes pathAnim-2 {
  0% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 34.31907061412407,293.2342614037959 68.63814122824814,286.4685228075918 100,287 C 131.36185877175186,287.5314771924082 159.7665057011315,295.3601701734288 196,294 C 232.2334942988685,292.6398298265712 276.29583596722597,282.09079649869295 313,282 C 349.70416403277403,281.90920350130705 379.05015042996445,292.27664383179916 409,289 C 438.94984957003555,285.72335616820084 469.5035623129163,268.80262817411045 506,269 C 542.4964376870837,269.19737182588955 584.93560031837,286.51284347175925 617,295 C 649.06439968163,303.48715652824075 670.7540364136036,303.14599793885264 708,294 C 745.2459635863964,284.85400206114736 798.0482540272159,266.9031647728303 838,272 C 877.9517459727841,277.0968352271697 905.0529474775335,305.2413429698264 934,315 C 962.9470525224665,324.7586570301736 993.73995606265,316.1314633478642 1031,310 C 1068.26004393735,303.8685366521358 1111.987228271867,300.2328036387168 1148,306 C 1184.012771728133,311.7671963612832 1212.3111308498821,326.9373220972685 1244,332 C 1275.6888691501179,337.0626779027315 1310.768248328605,332.017907972209 1344,325 C 1377.231751671395,317.982092027791 1408.6158758356974,308.99104601389547 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  25% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 33.77022914764348,302.1347365741649 67.54045829528695,304.2694731483297 102,295 C 136.45954170471305,285.7305268516703 171.60839596649564,265.056843980846 203,267 C 234.39160403350436,268.943156019154 262.02595783873045,293.50315092828623 296,298 C 329.97404216126955,302.49684907171377 370.2877726785826,286.93055230600913 409,278 C 447.7122273214174,269.06944769399087 484.8229514469389,266.77463984767735 519,263 C 553.1770485530611,259.22536015232265 584.420421533662,253.97088830328164 617,268 C 649.579578466338,282.02911169671836 683.495362418413,315.3418069391962 716,328 C 748.504637581587,340.6581930608038 779.5981287926862,332.6618839399337 815,333 C 850.4018712073138,333.3381160600663 890.1121224108421,342.0106573010688 924,331 C 957.8878775891579,319.9893426989312 985.9533815639456,289.29548685579095 1016,291 C 1046.0466184360544,292.70451314420905 1078.0743513333753,326.8073952757674 1115,330 C 1151.9256486666247,333.1926047242326 1193.7492131025526,305.47493204113954 1236,292 C 1278.2507868974474,278.52506795886046 1320.9287962564135,279.2928765596744 1355,283 C 1389.0712037435865,286.7071234403256 1414.5356018717932,293.3535617201628 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  50% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 32.30827112032203,299.5664506772448 64.61654224064407,299.13290135448966 96,304 C 127.38345775935593,308.86709864551034 157.8421021577458,319.0348452592861 197,329 C 236.1578978422542,338.9651547407139 284.0150491283728,348.72771760836594 322,334 C 359.9849508716272,319.27228239163406 388.09770132876287,280.0542843072502 423,283 C 457.90229867123713,285.9457156927498 499.5941455565759,331.05514516263315 531,328 C 562.4058544434241,324.94485483736685 583.5257164449337,273.72513504221695 612,273 C 640.4742835550663,272.27486495778305 676.3029886636895,322.04431466849877 718,336 C 759.6970113363105,349.95568533150123 807.2623289003086,328.0976062837878 839,313 C 870.7376710996914,297.9023937162122 886.6476957350764,289.56526019635004 916,288 C 945.3523042649236,286.43473980364996 988.1468881593855,291.6413529308122 1030,298 C 1071.8531118406145,304.3586470691878 1112.7647516273812,311.8693280804008 1145,312 C 1177.2352483726188,312.1306719195992 1200.7941053310897,304.88133474758445 1235,307 C 1269.2058946689103,309.11866525241555 1314.0588270482601,320.6053329292616 1350,321 C 1385.9411729517399,321.3946670707384 1412.9705864758698,310.69733353536924 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  75% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 39.17018453441793,290.42580319135817 78.34036906883586,280.85160638271634 111,278 C 143.65963093116414,275.14839361728366 169.8087082590745,279.01937766049275 206,278 C 242.1912917409255,276.98062233950725 288.4247978948662,271.0708829753125 325,272 C 361.5752021051338,272.9291170246875 388.4921001614607,280.6970904382575 421,277 C 453.5078998385393,273.3029095617425 491.6068014592911,258.14075527165755 523,270 C 554.3931985407089,281.85924472834245 579.0806940013742,320.739888475112 612,332 C 644.9193059986258,343.260111524888 686.0704225352113,326.89969082789423 728,323 C 769.9295774647887,319.10030917210577 812.6376158577805,327.66134821331116 839,326 C 865.3623841422195,324.33865178668884 875.3791140336666,312.45491631886114 910,310 C 944.6208859663334,307.54508368113886 1003.8459280075535,314.5189865112444 1045,311 C 1086.1540719924465,307.4810134887556 1109.23717393612,293.4691376361613 1136,284 C 1162.76282606388,274.5308623638387 1193.2053762479677,269.60446294411037 1229,283 C 1264.7946237520323,296.39553705588963 1305.9413210720093,328.11301058739707 1342,334 C 1378.0586789279907,339.88698941260293 1409.0293394639953,319.94349470630146 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
  100% {
    d: path("M 0,400 C 0,400 0,300 0,300 C 34.31907061412407,293.2342614037959 68.63814122824814,286.4685228075918 100,287 C 131.36185877175186,287.5314771924082 159.7665057011315,295.3601701734288 196,294 C 232.2334942988685,292.6398298265712 276.29583596722597,282.09079649869295 313,282 C 349.70416403277403,281.90920350130705 379.05015042996445,292.27664383179916 409,289 C 438.94984957003555,285.72335616820084 469.5035623129163,268.80262817411045 506,269 C 542.4964376870837,269.19737182588955 584.93560031837,286.51284347175925 617,295 C 649.06439968163,303.48715652824075 670.7540364136036,303.14599793885264 708,294 C 745.2459635863964,284.85400206114736 798.0482540272159,266.9031647728303 838,272 C 877.9517459727841,277.0968352271697 905.0529474775335,305.2413429698264 934,315 C 962.9470525224665,324.7586570301736 993.73995606265,316.1314633478642 1031,310 C 1068.26004393735,303.8685366521358 1111.987228271867,300.2328036387168 1148,306 C 1184.012771728133,311.7671963612832 1212.3111308498821,326.9373220972685 1244,332 C 1275.6888691501179,337.0626779027315 1310.768248328605,332.017907972209 1344,325 C 1377.231751671395,317.982092027791 1408.6158758356974,308.99104601389547 1440,300 C 1440,300 1440,400 1440,400 Z");
  }
}
.proj-shadow {
  position: relative;
}
.proj-shadow::after {
  content: "";
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: rgb(23, 23, 23);
  -webkit-box-shadow: 0.5vw 0 1vw 1vw rgb(23, 23, 23);
          box-shadow: 0.5vw 0 1vw 1vw rgb(23, 23, 23);
  width: 100%;
  position: absolute;
  top: 0;
  height: 0.5vw;
  left: 0;
  z-index: 2;
}
.proj-shadow::before {
  content: "";
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: rgb(23, 23, 23);
  -webkit-box-shadow: 0.5vw 0 1vw 1vw rgb(23, 23, 23);
          box-shadow: 0.5vw 0 1vw 1vw rgb(23, 23, 23);
  width: 100%;
  position: absolute;
  top: 100%;
  height: 0.5vw;
  left: 0;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  z-index: 2;
}

.proj {
  min-height: 35vh;
}

.card-detail {
  background: rgb(255, 72, 0);
  background: -webkit-gradient(linear, left bottom, left top, color-stop(30%, rgb(255, 72, 0)), to(rgba(255, 72, 0, 0.9192051821)));
  background: linear-gradient(0deg, rgb(255, 72, 0) 30%, rgba(255, 72, 0, 0.9192051821) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff4800", endColorstr="#ff4800", GradientType=1);
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  height: 90%;
}

.card-img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.my-transition {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.show-card-detail:hover .card-img {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  border-radius: 10px;
}
.show-card-detail:hover .card-detail {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  -webkit-box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.4);
          box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.4);
}

.hire-bg {
  background-image: url("assets/img/dev2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.hire-sec {
  min-height: 60vh;
}

.anim-section {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.anim-section.animate {
  opacity: 1;
}

.nv-anim {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-box-shadow: 0 5px 9px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 9px rgba(0, 0, 0, 0.2);
}
.nv-anim.animate-nv {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.transition0 {
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

.card {
  position: relative;
  padding: 25px;
  height: 460px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
  max-width: 40%;
}

.card:nth-child(4n+1),
.card:nth-child(4n+4) {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          flex: 0 0 60%;
  max-width: 60%;
}

.card:nth-child(odd) {
  padding-left: 0;
}

.card:nth-child(even) {
  padding-right: 0;
}

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 20px;
  background: #1c1c1e;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  pointer-events: none;
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 420px;
  width: 100vw;
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 300px;
}

.open .title-container {
  top: 30px;
  left: 30px;
}

h2 {
  color: #fff;
  margin: 8px 0;
}

.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  max-width: 990px;
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.content-container {
  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;
}

p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}

@media only screen and (max-width: 800px) {
  .card {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .card:nth-child(4n+1),
.card:nth-child(4n+4) {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .card {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
    max-width: 100%;
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .card:nth-child(4n+1),
.card:nth-child(4n+4) {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
    max-width: 100%;
  }
  .card-content-container.open {
    padding: 0;
  }
}
.proj-img {
  max-height: 80vh !important;
  -o-object-position: center;
     object-position: center;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(157, 157, 157, 0.604);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.737);
}

::-webkit-scrollbar-track {
  background: transparent;
}

.proj-modal {
  height: 90vh;
  overflow: auto;
}

.copy-inp {
  background-color: rgba(255, 72, 0, 0);
  padding: 0;
}/*# sourceMappingURL=MyApp.css.map */